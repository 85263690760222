import styles from "./Experience.module.css";
import { BsPatchCheckFill } from "react-icons/bs";

const Experience = () => {
  return (
    <section id="experience">
      <h5>What skills I Have</h5>
      <h2>My Experience</h2>
      <div className={`container ${styles.experienceContainer}`}>
        <div className={styles.experiences}>
          <h3>Frontend Development</h3>
          <div className={styles.experienceContent}>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>HTML</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>CSS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>SASS</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>JavaScript</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>React</h4>
                <small className="text-light">Experienced</small>
              </div>
            </article>
          </div>
        </div>
        {/*End of Frontend*/}

        <div className={styles.experiences}>
          <h3>Backend Development</h3>
          <div className={styles.experienceContent}>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>Node JS</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>MongoDB</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>Express</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
            <article className={styles.experienceDetails}>
              <BsPatchCheckFill className={styles.experienceDetailsIcon} />
              <div>
                <h4>Python</h4>
                <small className="text-light">Intermediate</small>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};
export default Experience;
