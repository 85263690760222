import { useState } from "react";
import styles from "./Nav.module.css";
import logo from "../../assets/logo.png";
import { BiUser } from "react-icons/bi";
import { BiBook } from "react-icons/bi";
import { BiMessageSquareDetail } from "react-icons/bi";
const Nav = () => {
  const [activeNav, setActiveNav] = useState("#");

  const activeNavHandler = (page) => setActiveNav(page);

  return (
    <nav>
      <a
        href="#"
        onClick={() => setActiveNav("#")}
        className={activeNav == "#" ? styles.active : ""}
      >
        <img src={logo} alt="Swethang Mukundan logo" />
      </a>
      <a
        href="#about"
        onClick={() => setActiveNav("#about")}
        className={activeNav == "#about" ? styles.active : ""}
      >
        <BiUser />
      </a>
      <a
        href="#experience"
        onClick={() => setActiveNav("#experience")}
        className={activeNav == "#experience" ? styles.active : ""}
      >
        <BiBook />
      </a>

      <a
        href="#contact"
        onClick={() => setActiveNav("#contact")}
        className={activeNav == "#contact" ? styles.active : ""}
      >
        <BiMessageSquareDetail />
      </a>
    </nav>
  );
};
export default Nav;
