// import Swiper core and required modules
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import styles from "./Testimonials.module.css";

import Avt1 from "../../assets/surendran.jpg";
import Avt2 from "../../assets/priyesh.jpg";

const datas = [
  {
    avatar: Avt1,
    name: "Surendran KV",
    review:
      "Exceptional work, I am very happy to work with Swethang in my future projects too",
  },
  {
    avatar: Avt2,
    name: "Priyesh Navath",
    review:
      "Great experience, to work with Swethang. He is an incredibly talented web developer.",
  },
];

const Testimonials = () => {
  return (
    <section id="testimonials">
      <h5>Reviews from clients</h5>
      <h2>Testimonials</h2>
      <Swiper
        className={`container ${styles.testimonialsContainer}`}
        modules={[Pagination]}
        spaceBetween={40}
        slidesPerView={1}
        pagination={{ clickable: true }}
      >
        {datas.map((data, index) => {
          return (
            <SwiperSlide className={styles.testimonial}>
              <div className={styles.clientAvatar}>
                <img src={data.avatar} alt={data.name} />
              </div>
              <h5 className={styles.clientName}>{data.name}</h5>
              <small className={styles.clientReview}>{data.review}</small>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </section>
  );
};
export default Testimonials;
