import React, { useRef } from "react";
import emailjs from "@emailjs/browser";

import styles from "./Contact.module.css";
import { MdOutlineMail } from "react-icons/md";
import { RiMessengerLine } from "react-icons/ri";
import { BsWhatsapp } from "react-icons/bs";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ipvhpup",
        "template_k81bguo",
        form.current,
        "ORbxSTmvZx2xDkga9"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };

  return (
    <section id="contact">
      <h5>Get In Touch</h5>
      <h2>Contact Me</h2>
      <div className={`container ${styles.contactContainer}`}>
        <div className={styles.contactOptions}>
          <a
            href="mailto:swethangkuttu@gmail.com"
            target="_blank"
            className={`${styles.contactOption} ${styles.contactEmail}`}
          >
            <MdOutlineMail className={styles.contactOptionIcon} />
            <h4>Email</h4>
            <h5 className={styles.contactData}>mswethang@gmail.com</h5>
          </a>
          <a
            href="https://m.me/mswethang"
            target="_blank"
            className={`${styles.contactOption} ${styles.contactMessenger}`}
          >
            <RiMessengerLine className={styles.contactOptionIcon} />
            <h4>Messenger</h4>
            <h5 className={styles.contactData}>mswethang</h5>
          </a>
          <a
            href="https://wa.me/+919995695792"
            target="_blank"
            className={`${styles.contactOption} ${styles.contactWhatsapp}`}
          >
            <BsWhatsapp className={styles.contactOptionIcon} />
            <h4>Whatsapp</h4>
            <h5 className={styles.contactData}>+91 999 569 579 2</h5>
          </a>
        </div>
        <form ref={form} onSubmit={sendEmail} className={styles.contactForm}>
          <input
            type="text"
            name="name"
            placeholder="Your Full Name"
            required
          />
          <input type="email" name="email" placeholder="Your Email" required />
          <textarea
            name="message"
            rows="7"
            placeholder="Your Message"
            required
          />
          <button type="submit" className="btn btn-primary">
            Send Message
          </button>
        </form>
      </div>
    </section>
  );
};

export default Contact;
