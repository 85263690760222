import styles from "./Footer.module.css";
import logo from "../../assets/logo.png";
import { FiFacebook } from "react-icons/fi";
import { FiInstagram } from "react-icons/fi";
import { FiTwitter } from "react-icons/fi";

const Footer = () => {
  return (
    <footer>
      <a href="#" className={styles.footerLogo}>
        <img
          className={styles.footerLogoImg}
          src={logo}
          alt="Swethang Mukundan logo"
        />
      </a>
      <ul className={styles.permaLinks}>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#portfolio">Projects</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>
      <div className={styles.footerSocials}>
        <a href="https://www.facebook.com/mswethang" target="_blank">
          <FiFacebook />
        </a>
        <a href="https://www.instagram.com/mswethang" target="_blank">
          <FiInstagram />
        </a>
        <a href="https://twitter.com/mswethang" target="_blank">
          <FiTwitter />
        </a>
      </div>
      <div className={styles.footerCopy}>
        <small>&copy; Mswethang. All rights reserved.</small>
      </div>
    </footer>
  );
};
export default Footer;
