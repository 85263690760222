import Cta from "./Cta";
import Social from "./Social";
import styles from "./Header.module.css";
import SuperTyping from "../typing/SuperTyping";

const Header = () => {
  return (
    <header>
      <div className={`container ${styles.headerContainer}`}>
        <h5 className={styles.hello}>Hello I'm</h5>
        <h1 className={styles.sag}>Swethang Mukundan</h1>
        <h5 className={styles.dev}>Software Developer</h5>
        <Cta />
        <Social />
        <div className={styles.me}>
          <SuperTyping />
        </div>
        <a href="#contact" className={styles.scrollDown}>
          Scroll Down
        </a>
      </div>
    </header>
  );
};
export default Header;
