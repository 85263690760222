import styles from "./About.module.css";
import Me from "../../assets/about.jpg";
import { FiAward } from "react-icons/fi";
import { FiUsers } from "react-icons/fi";
import { VscFolderLibrary } from "react-icons/vsc";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>
      <div className={`container ${styles.aboutContainer}`}>
        <div className={styles.aboutMe}>
          <div className={styles.aboutMeImage}>
            <img src={Me} alt="Swethang Mukundan About Image" />
          </div>
        </div>
        <div className={styles.aboutContent}>
          <div className={styles.aboutCards}>
            <article className={styles.aboutCard}>
              <FiAward className={styles.aboutIcons} />
              <h5>Experience</h5>
              <small>4+ Years</small>
            </article>
            <article className={styles.aboutCard}>
              <FiUsers className={styles.aboutIcons} />
              <h5>Clients</h5>
              <small>20+ Worldwide</small>
            </article>
            <article className={styles.aboutCard}>
              <VscFolderLibrary className={styles.aboutIcons} />
              <h5>Projects</h5>
              <small>30+ completed</small>
            </article>
          </div>
          <p>
            Passionate Software Developer with over 4+ years of professional
            experience in Designing, Developing, and Maintaining responsive
            websites. Exceptionally focused, fast learner, and a team player.
          </p>
          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
