import styles from "./Portfolio.module.css";
import Img1 from "../../assets/dic.jpg";

const datas = [
  {
    id: 1,
    img: Img1,
    title: "District Industries Centre, Kannur",
    demo: "https://kannurdic.netlify.app",
  },
];

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent</h5>
      <h2>Project</h2>

      <div className={`container ${styles.portfolioContainer}`}>
        {datas.map((data) => {
          return (
            <article key={data.id} className={styles.portfolioItem}>
              <div className={styles.portfolioItemImage}>
                <img src={data.img} alt={data.title} />
              </div>
              <h3>{data.title}</h3>
              <div className={styles.portfolioItemCta}>
                <a
                  href={data.demo}
                  target="_blank"
                  className={`btn btn-primary ${styles.portfolioButton}`}
                >
                  Live
                </a>
              </div>
            </article>
          );
        })}
      </div>
    </section>
  );
};
export default Portfolio;
