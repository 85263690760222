import styles from "./Social.module.css";
import { BsLinkedin } from "react-icons/bs";
import { BsGithub } from "react-icons/bs";
import { BsTwitter } from "react-icons/bs";

const Social = () => {
  return (
    <div className={styles.social}>
      <a href="https://www.linkedin.com/mswethang" target="_blank">
        <BsLinkedin />
      </a>
      <a href="https://www.github.com/" target="_blank">
        <BsGithub />
      </a>
      <a href="https://www.twitter.com/mswethang" target="_blank">
        <BsTwitter />
      </a>
    </div>
  );
};

export default Social;
