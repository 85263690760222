import { useEffect, useState } from "react";
import styles from "./SuperTyping.module.css";

const TYPING_INTERVAL = 150;
const PAUSE_MS = 1000;
const DELETING_INTERVAL = 50;

const superpowers = ["Design", "Develop", "Maintain"];

const useTypedSuperpower = (superpowers) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [phase, setPhase] = useState("typing");
  const [typedSuperpower, setTypedSuperpower] = useState("");

  useEffect(() => {
    switch (phase) {
      case "typing": {
        const nextTypedSuperpower = superpowers[selectedIndex].slice(
          0,
          typedSuperpower.length + 1
        );

        if (nextTypedSuperpower === typedSuperpower) {
          setPhase("pausing");
          return;
        }

        const timeout = setTimeout(() => {
          setTypedSuperpower(nextTypedSuperpower);
        }, TYPING_INTERVAL);

        return () => clearTimeout(timeout);
      }

      case "deleting": {
        if (!typedSuperpower) {
          const nextIndex = selectedIndex + 1;
          setSelectedIndex(superpowers[nextIndex] ? nextIndex : 0);
          setPhase("typing");
          return;
        }

        const nextRemaining = superpowers[selectedIndex].slice(
          0,
          typedSuperpower.length - 1
        );

        const timeout = setTimeout(() => {
          setTypedSuperpower(nextRemaining);
        }, DELETING_INTERVAL);

        return () => clearTimeout(timeout);
      }

      case "pausing":
      default:
        const timeout = setTimeout(() => {
          setPhase("deleting");
        }, PAUSE_MS);

        return () => clearTimeout(timeout);
    }
  }, [typedSuperpower, superpowers, selectedIndex, phase]);

  return { typedSuperpower, phase };
};

const SuperTyping = () => {
  const { typedSuperpower, phase } = useTypedSuperpower(superpowers);
  return (
    <h2>
      <p className={styles.superHead}>my Superpower?</p>
      <p
        className={`${styles.superpower} ${
          phase !== "deleting" ? styles.blinkingCursor : ""
        }`}
      >
        {typedSuperpower}
      </p>
    </h2>
  );
};

export default SuperTyping;
