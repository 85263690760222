import Resume from "../../assets/resume.pdf";
import styles from "./Cta.module.css";
const Cta = () => {
  return (
    <div className={styles.cta}>
      <a href={Resume} download className="btn">
        Download Resume
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
};

export default Cta;
